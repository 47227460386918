gsap.registerPlugin("ScrollTrigger");

pageTransitionOut = () => {

    var timeline = gsap.timeline();

    timeline.to(".c-header__logo-curtain", {
        autoAlpha: 0
    });

    timeline.to(".page-transition", {
        top: "-100%",
        delay: .3
    });

}

pageTransitionOut();
pageTransitionIn = () => {
    var timeline = gsap.timeline();

    timeline.to("header", {
        zIndex: 1
    });

    timeline.to(".page-transition", {
        duration: 0.5,
        height: "100%",
        top: "0%"
    });

    timeline.to(".c-header__logo-curtain", {
        autoAlpha: 1
    });
}
var intMinAnim = false;
var current = 0;
function animateMinValue(id, stop, dir) {
    var time = 600 / Math.abs(current - stop);
    if (intMinAnim !== false) { clearInterval(intMinAnim); intMinAnim = false; }
    var obj = document.getElementById(id);
    var objH = document.getElementById('cp-hour');
    intMinAnim = setInterval(function () {
        if (current == stop) {
            clearInterval(intMinAnim);
        } else {
            if (dir != 'down') {
                current++;
            } else {
                current--;
            }
        }
        // Update the contents of the element
        if (current % 60 < 10) {
            obj.innerHTML = '0' + current % 60;
            objH.innerHTML = parseInt(current / 60);
        } else {
            obj.innerHTML = current % 60;
            objH.innerHTML = parseInt(current / 60);
        }
    }, time);
}
function scrollCalc() {
    const hW = $(window).height();
    const hB = $('html').height();
    const wS = window.scrollY;
    $('#percScroll').html(parseInt(wS * 100 / (hB - hW)) + '%');
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}


(function ($) {
    let bgwhite = false;

    scrollCalc();
    $(".button-scroll").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 800, 'swing');
    });


    // control bg header according to first content
    if (!$('#main').find(">:first-child").find(">:first-child").hasClass('js-swiper-home')) {
        $('header.c-header').addClass('has-background');
        bgwhite = true;
    }
    //controla url si tiene parámetro act = 1 existiendo el bloque formulario desplegable se abre por defecto y scrollea hasta él
    if (getParameterByName('go') !== "") {
        var idH = getParameterByName('go');
        var element_top_position = parseInt($('#' + idH).offset().top);
        var body = $("html, body");
        body.stop().animate({ scrollTop: element_top_position - 100 }, '600', 'swing');
    }

    //
    $(".c-process__item").on("click", function () {

        let id = $(this).data("step");

        $(".c-process__step-" + id).addClass("is-active").siblings().removeClass("is-active");

    });


    var last_scroll_top = 0;
    var top_display = 30;

    function header() {

        last_scroll_top = 0;
        top_display = 30;


        $(window).on('scroll', function () {

            var scroll_top = $(this).scrollTop();

            if ((scroll_top > last_scroll_top) && (scroll_top > top_display)) {
                $('.c-header').addClass('has-transform');
            } else {
                $('.c-header').removeClass('has-transform');
            }

            last_scroll_top = scroll_top;

            if (!bgwhite) {
                if (scroll_top > top_display) {
                    $('.c-header').addClass('has-background');
                } else {
                    $('.c-header').removeClass('has-background');
                }
            }
        });
    }

    // footer accordeon 

    function hide_accordeon() {

        if ($(window).width() < 567) {
            $(".c-footer__title").addClass("collapsed");
            $(".c-footer .accordion-collapse").removeClass("show");
        }
    }

    // popup

    function popup() {

        $("#js-header__myarin, #js-header__myarin-mobile").on('click', function () {
            $('.c-header__popup').fadeToggle();

            $("#js-header__popup-close").on('click', function () {
                $('.c-header__popup').fadeOut();
            });
        });
    }

    function menu_mobile() {

        var $menu_open = $('#js-menu-open'),
            $menu_close = $('#js-menu-close'),
            $menuWrap = $(".b-menu-mobile");

        $menu_open.on("click", function () {
            $menuWrap.addClass("is-show");
            $(".b-menu-mobile__inner").addClass("is-active");
        });

        $menu_close.on("click", function () {
            $menuWrap.removeClass("is-show");
            $(".b-menu-mobile__inner").removeClass("is-active");
        });
    }

    // swiper gallery

    function swiper_gallery() {

        var swiperGallery = new Swiper(".mySwiperGallery", {
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                },
                // when window width is >= 767px
                767: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    grabCursor: true,
                }
            }
        });
    }

    // swiper member

    function swiper_member() {

        const swiperMember = new Swiper('.mySwiperMember', {
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                },
                // when window width is >= 767px
                767: {
                    slidesPerView: 3,
                    spaceBetween: 70,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    grabCursor: true,
                }
            }
        });
    }

    function swiper_home() {

        var swiper = new Swiper(".mySwiper", {
            slidesPerView: 1,
            // effect: "fade",
            // loop: true,
            grabCursor: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            // autoplay: {
            //     delay: 5000,
            // }

            on: {
                // slideChange: function () {
                //     var videos = document.querySelectorAll('.js-swiper-target');

                //     // console.log(videos);
                //     Array.prototype.forEach.call(videos, function (video) {
                //         if (video.tagName.toLowerCase() === 'video') {
                //             video.pause();
                //         } else {
                //             var src = video.src;
                //             video.src = src;
                //         }
                //     });
                // },

                transitionStart: function () {

                    var videos = document.querySelectorAll('.js-swiper-target');

                    Array.prototype.forEach.call(videos, function (video) {
                        video.pause();
                    });
                },

                transitionEnd: function () {

                    var activeIndex = this.activeIndex;
                    var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                    var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                    activeSlideVideo.play();
                },
            },
        });

        gsap.fromTo(".c-carrousel__title", { autoAlpha: 0 }, { autoAlpha: 1, duration: .5, delay: .2 });
        gsap.fromTo(".c-carrousel__btn", { autoAlpha: 0 }, { autoAlpha: 1, duration: .5, delay: .2 });

        swiper.on('slideChange', function () {

            gsap.fromTo(".c-carrousel__title", { autoAlpha: 0 }, { autoAlpha: 1, duration: .5, delay: .2 });
            gsap.fromTo(".c-carrousel__btn", { autoAlpha: 0 }, { autoAlpha: 1, duration: .5, delay: .2 });

        });

    }

    function submenu() {

        $(".c-header .c-menu__submenu").mouseenter(function (e) {
            e.preventDefault;
            $(".c-header").addClass("is-hovered");
        });

        $(".c-header .c-menu__submenu").mouseleave(function () {
            $(".c-header").removeClass("is-hovered");
        });
    }

    var Animations = function (selector) {

        // var duration = 1.1;

        // this.setDuration = function (du) {
        //     duration = du;
        //     return this;
        // }

        this.fade_in = function () {

            let target = gsap.utils.toArray(".animate__fade-in");

            //arrow function
            target.forEach((element, index) => {

                let fadeIn = gsap.from(element, {
                    autoAlpha: 0,
                    ease: "power3",
                    duration: 1.1
                });

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: fadeIn,
                    // markers: true
                });
            });
        }

        this.fade_in_up = function () {

            let target = gsap.utils.toArray(".animate__fade-in-up");

            //arrow function
            target.forEach((element, index) => {

                let fadeInUp = gsap.from(element, {
                    autoAlpha: 0,
                    y: 30,
                    ease: "power3",
                    duration: 1.1
                });

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: fadeInUp,
                    // markers: true
                });
            });
        }

        this.slide_left = function () {

            let target = gsap.utils.toArray(".animate__slide-left");

            //arrow function
            target.forEach((element, index) => {

                let slideLeft = gsap.from(element, {
                    autoAlpha: 0,
                    // x: 30,
                    x: -100,
                    ease: "elastic.out(1, 0.3)",
                    duration: 2
                });

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: slideLeft,
                    // markers: true
                });
            });
        }

        this.fade_in_up_custom = function () {

            let target = gsap.utils.toArray(".animate__fade-in-up-custom ");

            //arrow function
            target.forEach((element, index) => {

                let fadeInUp = gsap.from(element, {
                    autoAlpha: 0,
                    y: 30,
                    ease: "power3",
                    duration: 1.1
                });

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: fadeInUp,
                    // markers: true
                });
            });
        }

        this.fade_in_up_mobile = function () {

            let target = gsap.utils.toArray(".animate__fade-in-up-mobile");

            //arrow function
            target.forEach((element, index) => {

                // console.log(index);


                let fadeInUp = gsap.from(element, {
                    autoAlpha: 0,
                    y: 30,
                    ease: "power3",
                    duration: 1.1,
                    onComplete: () => {
                        element.children[0].children[1].play();
                    }
                });

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: fadeInUp,
                });
            });
        }

        this.fade_in_parallax = function () {

            let banners = document.querySelectorAll(".c-parallax")

            banners.forEach((element) => {

                let background = element.querySelector(".c-parallax__background-img")
                let headings = element.querySelectorAll(".c-parallax__text")

                let tl = gsap.timeline()
                    .from(background, { filter: "brightness(0.1)" })
                    .from(headings, { y: 30, stagger: 0.1, duration: 1.1 }, 0)

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: tl,
                    // markers: true
                })

            })
        }

        this.process = function () {

            gsap.defaults({
                ease: "power"
            })

            gsap.set(".c-process__banner-alt",{
                autoAlpha: 0,
                x: -100
            })

            gsap.set(".c-process__timer", { autoAlpha: 0 });
            gsap.set(".c-process__line", { autoAlpha: 0 });
            gsap.set(".c-process__wrapper-icons", { autoAlpha: 0 });
            gsap.set(".c-process__content", { autoAlpha: 0 });

            let pinSection = gsap.utils.toArray('.c-process__pin');

            pinSection.forEach((element, index) => {

                if (index == 1) {

                    ScrollTrigger.create({
                        trigger: element,
                        start: 'top top',
                        // end: "bottom bottom+=100",
                        end: "+=600%",
                        // endTrigger: ".c-process__wrapper-icons6",
                        pin: true,
                        pinSpacing: true,
                        // markers: true,
                        onUpdate: self => {
                            // console.log('selfp', self.progress);
                            let dir = 'down';
                            if (self.direction == 1) {
                                dir = 'up';
                            }
                            if (self.progress < 0.1) {
                                animateMinValue('cp-min', 0, dir);
                                //animateHourValue('cp-hour',0,dir);
                            } else if (self.progress < 0.2) {
                                animateMinValue('cp-min', 5, dir);
                                //animateHourValue('cp-hour',0,dir);

                            } else if (self.progress < 0.3) {
                                animateMinValue('cp-min', 15, dir);
                                //animateHourValue('cp-hour',0,dir);

                            } else if (self.progress < 0.4) {
                                animateMinValue('cp-min', 90, dir);
                                //animateHourValue('cp-hour',1,dir);

                            } else if (self.progress < 0.5) {
                                animateMinValue('cp-min', 240, dir);
                                //animateHourValue('cp-hour',4,dir);
                            }

                            if (self.progress > 0.01) {

                                gsap.to(".c-process__timer", { autoAlpha: 1 })
                                gsap.to(".c-process__line", { autoAlpha: 1 })
                                gsap.to(".c-process__content1", { autoAlpha: 1 })
                                gsap.to(".c-process__wrapper-icons1", { autoAlpha: 1 })

                                $(".c-process__content1").removeClass("opacity-25")
                                $(".c-process__content1").fadeIn()

                                //$(".min").html("00")

                                $(".c-process__wrapper-icons1 .c-process__num-l").addClass("c-process__num-l--scale");

                                document.getElementById('aemail').play();

                            }

                            if (self.progress < 0.01) {

                                gsap.to(".c-process__line", { autoAlpha: 0 })

                                gsap.to(".c-process__content1", { autoAlpha: 0 })
                                gsap.to(".c-process__wrapper-icons1", { autoAlpha: 0 })

                                gsap.to(".c-process__timer", { autoAlpha: 0 })

                                document.getElementById('aemail').stop();
                            }

                            if (self.progress > 0.1) {

                                $(".c-process__content1").addClass("opacity-25")
                                $(".c-process__content2").removeClass("opacity-25");

                                gsap.to(".c-process__content2", { autoAlpha: 1 })
                                gsap.to(".c-process__wrapper-icons2", { autoAlpha: 1 })


                                $(".c-process__wrapper-icons1 .c-process__num-l").removeClass("c-process__num-l--scale");
                                $(".c-process__wrapper-icons2 .c-process__num-r").addClass("c-process__num-r--scale");
                                document.getElementById('apeople').play();

                            }

                            if (self.progress < 0.1) {
                                gsap.to(".c-process__content2", { autoAlpha: 0 })
                                gsap.to(".c-process__wrapper-icons2", { autoAlpha: 0 })
                                document.getElementById('apeople').stop();
                            }

                            if (self.progress > 0.2) {

                                $(".c-process__content2").addClass("opacity-25")
                                $(".c-process__content3").removeClass("opacity-25")

                                gsap.to(".c-process__content3", { autoAlpha: 1 })
                                gsap.to(".c-process__wrapper-icons3", { autoAlpha: 1 })


                                $(".c-process__wrapper-icons2 .c-process__num-r").removeClass("c-process__num-r--scale");
                                $(".c-process__wrapper-icons3 .c-process__num-l").addClass("c-process__num-l--scale");
                                document.getElementById('apersona').play();

                            }

                            if (self.progress < 0.2) {
                                gsap.to(".c-process__content3", { autoAlpha: 0 })
                                gsap.to(".c-process__wrapper-icons3", { autoAlpha: 0 })
                                document.getElementById('apersona').stop();
                            }

                            if (self.progress > 0.3) {

                                $(".c-process__content3").addClass("opacity-25")
                                $(".c-process__content4").removeClass("opacity-25")

                                gsap.to(".c-process__content4", { autoAlpha: 1 })
                                gsap.to(".c-process__wrapper-icons4", { autoAlpha: 1 })


                                $(".c-process__wrapper-icons3 .c-process__num-l").removeClass("c-process__num-l--scale");
                                $(".c-process__wrapper-icons4 .c-process__num-r").addClass("c-process__num-r--scale");
                                document.getElementById('acamion').play();
                            }
                            if (self.progress < 0.3) {

                                gsap.to(".c-process__content4", { autoAlpha: 0 })
                                gsap.to(".c-process__wrapper-icons4", { autoAlpha: 0 })

                                document.getElementById('acamion').stop();
                            }

                            if (self.progress > 0.4) {

                                $(".c-process__content4").addClass("opacity-25")

                                gsap.to(".c-process__content5", { autoAlpha: 1 });
                                gsap.to(".c-process__wrapper-icons5", { autoAlpha: 1 });


                                $(".c-process__wrapper-icons4 .c-process__num-r").removeClass("c-process__num-r--scale");
                                $(".c-process__wrapper-icons5 .c-process__num-l").addClass("c-process__num-l--scale");

                                document.getElementById('apin').play();
                            }

                            if (self.progress < 0.4) {

                                gsap.to(".c-process__content5", { autoAlpha: 0 })
                                gsap.to(".c-process__wrapper-icons5", { autoAlpha: 0 })
                                document.getElementById('apin').stop();
                            }

                            if (self.progress > 0.5) {

                                $(".c-process__content1").removeClass("opacity-25");
                                $(".c-process__content2").removeClass("opacity-25");
                                $(".c-process__content3").removeClass("opacity-25");
                                $(".c-process__content4").removeClass("opacity-25");
                                $(".c-process__content5").removeClass("opacity-25");

                                gsap.to(".c-process__content6", { autoAlpha: 1 })
                                gsap.to(".c-process__wrapper-icons6", { autoAlpha: 1 })

                                $(".c-process__timer").addClass("opacity-0")

                                $(".c-process__wrapper-icons5 .c-process__num-l").removeClass("c-process__num-l--scale");
                                $(".c-process__wrapper-icons6 .c-process__num-r").addClass("c-process__num-r--scale");
                                document.getElementById('aphone').play();
                            }

                            if (self.progress < 0.5) {
                                gsap.to(".c-process__content6", { autoAlpha: 0 })
                                gsap.to(".c-process__wrapper-icons6", { autoAlpha: 0 })

                                $(".c-process__timer").removeClass("opacity-0")
                                document.getElementById('aphone').stop();
                            }
                            if (self.progress <= 0.75) {
                                gsap.set(".c-process__banner", { yPercent: 0 });
                            }
                            if (self.progress > 0.75) {
                                gsap.to(".c-process__banner", { yPercent: (0.75 - self.progress) * 400 })
                            }
                            if (self.progress > 0.95) {

                               
                                gsap.to(".c-process__banner-alt", {
                                    autoAlpha: 1,
                                    x: 0,
                                    ease: "elastic.out(1, 0.3)",
                                    duration: 2
                                });
                            }
                        },
                    });

                } else {

                    ScrollTrigger.create({
                        trigger: element,
                        start: 'top top',
                        pin: true,
                        pinSpacing: false,
                        // markers: true
                    });
                }
            });
        }


        this.hide_arrow = function () {

            let scrollTimer = null;
            // const elementToHide = document.querySelector('.button-scroll');

            let container_limit = document.querySelector(".c-footer");
            let target = document.querySelector(".c-generic__arrow");
            window.addEventListener('scroll', () => {
                var height = $(window).scrollTop();
                if (height < 100) {
                    $(".button-scroll").removeClass('show');
                    target.classList.remove('hide');
                } else {
                    $(".button-scroll").addClass('show');
                    target.classList.add('hide')

                    if (scrollTimer) {
                        window.clearTimeout(scrollTimer);
                    }

                    scrollTimer = window.setTimeout(function () {
                        $(".button-scroll").removeClass('show');
                        scrollTimer = null;
                    }, 1000);
                }

            })
        }

        this.run = function (fn, args) {
            if ($(selector).length > 0) fn(args);
        }

        return this;
    }


    function getAnchor() {
        var currentUrl = document.URL,
            urlParts = currentUrl.split('#');
        // console.log(urlParts)
        return (urlParts.length > 1) ? urlParts[1] : null;
    }

    function callFn(selector, fn, args) { if ($(selector).length > 0) fn(args); }

    $(window).on("load", function () {

        callFn(".js-header", header);
        callFn(".js-hide-accordeon", hide_accordeon);
        if (getAnchor()) {
            setTimeout(function () {
                gsap.to(window, {
                    duration: 1,
                    scrollTo: "#" + getAnchor(),
                });
            }, 750);
        }

        callFn(".js-popup", popup);
        callFn(".js-menu-mobile", menu_mobile);
        callFn(".js-swiper-gallery", swiper_gallery);
        callFn(".js-swiper-member", swiper_member);
        callFn(".js-swiper-home", swiper_home);
        callFn(".js-submenu", submenu);

        //animations
        var animations = new Animations(".js-animations");

        animations.run(animations.process);
        // animations.run(animations.test);
        animations.run(animations.hide_arrow);
        animations.run(animations.fade_in);
        animations.run(animations.fade_in_up);
        animations.run(animations.slide_left);
        animations.run(animations.fade_in_up_mobile);
        animations.run(animations.fade_in_parallax);

        if (document.querySelector('.vlite-js')) {
            $(".vlite-js").each(function (i) {
                if ($(this).hasClass('ytb')) {
                    new Vlitejs(this, {
                        options: {
                            muted: true
                        },
                        provider: ["youtube"],
                        onReady: function (player) {
                            player.pause();
                            player.unMute();
                        },
                    });
                } else if ($(this).hasClass('vim')) {
                    new Vlitejs(this, {
                        options: {
                            muted: true
                        },
                        provider: ["vimeo"],
                        onReady: function (player) {
                            player.pause();
                            player.unMute();
                        },
                    });

                } else {
                    // console.log(this)
                    new Vlitejs(this, {
                        options: {
                            muted: true
                        },
                        onReady: function (player) {
                            player.pause();
                            player.unMute();
                        },
                    });
                }
            })
        }


        if (document.getElementById('modalContacto')) {
            document.querySelector('.button-contact').addEventListener('click', () => {
                document.body.classList.add('show-contact')
            })
            document.querySelector('#modalContacto .btn-close').addEventListener('click', () => {
                document.body.classList.remove('show-contact')
            })
        }

    });



})(jQuery);
$(window).resize(function () {
    scrollCalc();
});
$(window).scroll(function () {
    scrollCalc();
});